// this *variable* will be replaced during the build by the github actions workflow
export const appVersion = "4a55de8.1737124327"

export enum Feature {
	Transactions = "transactions",
	TransactionSourceAPI = "transaction-source-api",
	TransactionSourceTiles = "transaction-source-tiles",
}

class UI {
	private readonly features: string[]

	constructor(features: string[] = []) {
		this.features = features
	}

	has(feature: string): boolean {
		return this.features.includes(feature)
	}
}

export const config = {
	layers: [
		"cadastre",
		"administrative-divisions/regions",
		"administrative-divisions/rcms",
		"administrative-divisions/metropolitan-areas",
		"administrative-divisions/municipalities",
		"administrative-divisions/boroughs",
		"administrative-divisions/postal-codes",
		"agricultural-zones",
	],
	restrictedLayers: ["prospects", "urban-planning", "land-use", "floodable-zones", "wetlands"],
	filters: ["landerz", "off_market"],
	restrictedFilters: ["transactions"],
	ui: new UI([Feature.Transactions, Feature.TransactionSourceTiles]),

	filtersDefault: {
		developmentType: [
			"Résidentiel",
			"Mixte",
			"Commercial",
			"Lotissement",
			"Inconnu",
			"Agricole",
			"Industriel",
			"Institutionnel",
		],
	},
}
